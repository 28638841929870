<template>
  <div class="border border-color-vio block rounded shadow p-3 mb-5 bg-white rounded">
    <h4 class="text-center content-blocks-title"><strong>{{ Block_Title }}</strong></h4>
    <hr/>
    <div class="row amp-step amp-step-container text-start">
      <div class="row">
        <div class="col-2 col-sm-2 col-md-2 relative"><span
            class="content-status-dot">{{ Step_Number }}</span>
        </div>
        <div class="col-10 col-sm-10 col-md-10 ">
          <h6 class="avproj-step-status font-weight-bold mt-3 mb-0"><strong>{{ Step_In_Progress }}</strong></h6>
          <h5 class="m-0 font-weight-bold"><strong>{{ Folder_In_Building }}</strong></h5>
          <p>{{ Folder_Date }}</p>
        </div>
      </div>
    </div>
    <p class="block-content-font text-start me-3" v-html="Step_Description"></p>

    <div class="text-center">
      <button v-if="showAccess" type="button" class="button-warning-cus to-validate-with-frost"  @click="$router.push({ path:TRACK_FOLDER_PATH })">{{ Block_Access_Button }}
        <div class="arrow-white"/>
      </button>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import {mapActions, useStore} from "vuex";
import events from '../../constants/events';
import emitter from '../../events/emitter';
import {map, steps} from '../../mapper/TimelineStatusMapper';
import {TRACK_FOLDER_PATH} from "../../router/pathes";
import { replaceVars } from '../../utils/string.js';

export default {
  setup() {
    const store = useStore();

    const getTimelineStatus = computed(() => store.getters[`timeline/getTimelineStatus`]);

    return {
      getTimelineStatus,
    }
  },
  props: {
    showAccess: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      Block_Title: "AVANCEMENT DE MON PROJET",
      Step_In_Progress: "ÉTAPE EN COURS",
      Step_Number: "- / -",
      Folder_In_Building: "---",
      Folder_Date: "",
      Folder_Text: "---",
      Step_Description: "---",
      Block_Access_Button: "Accéder",
    };
  },
  methods: {
    ...mapActions('timeline', ['getTimelineStatusByMainDossier']),
    getData() {
      this.getTimelineStatusByMainDossier()
        .then(this.whenTimelineStatusChange)
        .finally(() => {
          // emit event
          emitter.emit(events.home.progressDone);
        });
    },
    /**
     * @param String status
     */
    whenTimelineStatusChange(status) {
      if (!status) {
        return;
      }
      // update UI
      const step = map(status);
      this.Step_Number = step.index + '/' + steps.length;
      this.Folder_In_Building = step.name;
      this.Folder_Text = replaceVars(step.text);
      this.Step_Description = replaceVars(step.hint);
    }
  },
  mounted() {
    this.whenTimelineStatusChange(this.getTimelineStatus);
    this.getData();
  },
  created() {
    this.TRACK_FOLDER_PATH = TRACK_FOLDER_PATH
  }
}
</script>
