<template>
  <div class="input-group mb-3" @mouseover="hover = true">
    <div class="input-group-prepend">
            <span :class="['input-group-text', 'input-icon', 'input-blue-background pointer']"
                  @click="showUploadDocumentPopup()">
                <img src="@/assets/images/upload.svg">
            </span>
    </div>
    <input type="text" readonly class="form-control input-constituer-dossier"
           :placeholder="document.label" :aria-label="document.label"
           aria-describedby="basic-addon1"
           @click="showUploadDocumentPopup()">
  </div>
</template>

<script>
import emitter from '../../events/emitter';
import Document from '../../models/document'

export default {
  data() {
    return {
      Block_Doc_Juridique_To_Validate: "Vous devez lire et valider les documents juridiques pour pouvoir téléverser vos documents.",
      hover: false,
    };
  },
  props: {
    allowToUpload: {
      type: Boolean,
      default: false
    },
    document: Document
  },
  methods: {
    showUploadDocumentPopup() {
      emitter.emit('show-upload-popup', {document: this.document});
      //this.$emit('showUploadDocumentPopup', true)
    }
  }
}
</script>

<style scoped>

.activeTooltip {
  position: absolute;
}

</style>
