const documentImage = (annee) => {
    return {
        ["Acte de propriété"]: "stickers-acte-propriete",
        ["Dernière taxe foncière"]: "stickers-taxe-fonciere",
        ["Propriétaire : Dernière taxe foncière"]: "stickers-taxe-fonciere",
        ["Dernière taxe d'habitation"]: "stickers-taxe-habitation",
        ["Justificatif de domicile de moins de trois mois (Facture EDF ou Eau ou téléphone)"]: "stickers-attestation-domicile",
        ["Dernière quittance de loyer "]: "stickers-quittance-loyer",
        ["Locataire : Dernière quittance de loyer"]: "stickers-quittance-loyer",
        ["Attestation d'hébergement à titre gratuit"]: "stickers-attestation-honneur",
        ["recto-verso de la carte d'identité de l'hébergeant + justificatif de domicile de moins de trois mois"]: "stickers-carte-identite",
        ["Recto-verso de la pièce d'identité, ou du passeport, ou du titre de séjour"]: "stickers-carte-identite",
        ["Trois derniers mois de relevés de comptes bancaires de tous les comptes courants"]: "stickers-releves-comptes-x3",
        ["Trois derniers mois de relevés de comptes bancaires de tous les comptes courant"]: "stickers-releves-comptes-x3",
        ["RIB"]: "stickers-RIB",
        ["Copie complète du livret de famille"]: "stickers-livret-famille",
        ["Livret de famille"]: "stickers-livret-famille",
        ["Contrat de mariage"]: "stickers-contrat-de-mariage",
        ["Jugement de divorce"]: "stickers-jugement-divorce",
        ["Liquidation de communauté"]: "stickers-liquidation-communaute",
        ["Récépissé du Pacs"]: "stickers-recepisse-PACS",
        ["Trois derniers bulletins de salaires"]: "stickers-bulletin-paie-x3",
        [`CDI / CDD / INTERIM : Trois derniers bulletins de salaires + Bulletin de salaire de décembre ${annee}`]: "stickers-bulletin-paie-x3",
        [`Bulletin de salaire de décembre ${annee}`]: "stickers-bulletin-paie-x1",
        ["Contrat de travail"]: "stickers-contrat-travail",
        ["Dernier avis d'imposition sur les revenus"]: "stickers-avis-impots",
        ["Trois derniers avis d'imposition sur les revenus"]: "stickers-avis-impots-x3",
        ["Dernière déclaration pré-remplie des revenus ou derniers justificatifs de retraite"]: "stickers-declaration-revenus",
        ["Extrait Kbis ou inscription chambre des métiers"]: "stickers-extrait-KBIS",
        ["Trois derniers bilans ou liasses fiscales"]: "stickers-bilan-x3",
        ["Offres de prêts et tableaux d'amortissements des prêts professionnels"]: "stickers-tableau-amortissement",
        ["Trois derniers mois de relevés de comptes bancaires de tous les comptes professionnels"]: "stickers-releves-comptes-x3",
        ["Trois dernières déclarations"]: "stickers-3-dernieres-declarations",
        ["Bail de location en cours"]: "stickers-contrat-location",
        ["Justificatif CAF de moins de 3 mois"]: "stickers-attestation-CAF",
        ["Dernier relevé de pension d'invalidité"]: "stickers-attestation-pension",
        ["Offre de prêts"]: "stickers-ODP",
        ["Tableaux d'amortissement ou capitaux restants dus des prêts personnels"]: "stickers-tableau-amortissement",
        ["Les tableaux d'amortissement ou les attestations de capitaux restants dus des prêts"]: "stickers-tableau-amortissement",
        ["Dernier relevé mensuel de chaque crédit renouvelable"]: "stickers-releves-mensuel-credit-renouvelable",
        ["Tableaux d'amortissement et capitaux restants dus des prêts immobiliers"]: "stickers-tableau-amortissement",
        ["Offre de prêts immobiliers"]: "stickers-ODP",
        ["Assurance Multirisques Habitation"]: "stickers-attestation-assurance",
        ["Les photos intérieures et extérieures mettant en valeur le bien"]: "stickers-photos-bien",
        ["Plan d'appurement ou justificatif du propriétaire avec le montant du retard"]: "stickers-plan-apurement",
        ["Attestation sur l'honneur du prêteur avec le montant restant dû"]: "stickers-attestation-honneur",
        ["recto-verso de la carte d'identité du prêteur"]: "stickers-carte-identite",
        ["Bordereau de situation fiscale à retirer au Trésor Public"]: "stickers-attestation-fiscale",
        ["Notification de saisie"]: "stickers-notification-saisie",
        ["Notification de l'avis à tiers détenteur"]: "stickers-avis-tiers-detenteur",
        ["Justificatifs de dettes de l'organisme"]: "stickers-justificatif-dettes",
        ["Tableaux d'amortissement et capitaux restants dus des prêts immobiliers conservés"]: "stickers-tableau-amortissement"
    }
};

/**
 *
 * @param {string} documentLibelle
 * @param {number} timestamp
 * @returns {string} Document image name
 */
export function map(documentLibelle, timestamp) {
    const date = new Date(0)
    date.setSeconds(timestamp);//new Date accepte des millisecondes, une date à 0 (ms) avec setSeconds permet d'utiliser le timestamp
    const annee = date.getFullYear() - 1;
    return documentImage(annee)[documentLibelle];
}
