
class TimelineStep{
    /**
     * 
     * @param int index - step index
     * @param string name - step name
     * @param string text - step text
     * @param string hint - step hint text(displayed in '?' icon)
     */
    constructor(index, name, text, hint) {
        this.index = index;
        this.name = name;
        this.text = text;
        this.hint = hint;
    }
}

export default TimelineStep;
