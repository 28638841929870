class DocumentCategory {
    /**
     * 
     * @param Object obj 
     */
    constructor(obj) {
        this.id = parseInt(obj.id);
        this.label = obj.label; // String!
        this.visible = obj.visible ? true : false;
    }
}

export default DocumentCategory;
