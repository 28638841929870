import { mapActions } from "vuex"

export default {
    data() {
        return {
            documents: [],
            legalDocuments: [],
            documentsSigning: []
        }
    },
    methods: {
        ...mapActions('document', ['getDocumentListByMainDossier', 'getLegalDocumentListByMainDossier', 'getDocumentSigningByMainDossier']),
        /**
         * 
         * @param {*} legalDoc 
         * @returns {*} Document
         */
        seekDocument(legalDoc) {
            for(let i = 0; i < this.documents.length; i++) {
                if(this.documents[i].id === legalDoc.id) {
                    return this.documents[i];
                }
            }
        },
        /**
         * @description: when document list received
         * @param {Array} data
         */
        whenDocumentListReceived(data) {
            this.documents = data;
        },
        /**
         * @description: when legal document list received
         * @param {Array} data
         */
        whenLegalDocumentListReceived(data) {
            this.legalDocuments = data;
        },
        /**
         * @description: when legal document list received
         * @param {Array} data
         */
         whenDocumentsSigningListReceived(data) {
            this.documentsSigning = data;
        }
    },
    mounted() {
        this.unsubscribe = this.store.subscribe((mutation) => {
            if (mutation.type === 'document/SET_DOCUMENTS') {
                this.whenDocumentListReceived(mutation.payload);
            }
            if (mutation.type === 'document/SET_LEGAL_DOCUMENTS') {
                this.whenLegalDocumentListReceived(mutation.payload);
            }
            if (mutation.type === 'document/SET_DOCUMENTS_SIGNING'){
                this.whenDocumentsSigningListReceived(mutation.payload);
            }
        });

        this.whenDocumentListReceived(this.computedDocuments);
        this.whenLegalDocumentListReceived(this.computedLegalDocuments);
        this.whenDocumentsSigningListReceived(this.computedSigningDocuments);
        this.getData();
    },
    unmounted() {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
    }
}
