<template>
    <div class="popup">
        <div class="popup-inner rounded-3 border-popup-custom shadow">
            <h4 class="constituer-dossier-bold-content popup-title-up">IMPORTER UN FICHIER</h4>
            <span class="upload-popup-close-pos cursor-pointer"
                @click="closePopup()" ><strong>X<i class="bi bi-x-lg"></i></strong></span>
            <hr />
            <div class="row py-3">
                <div class="loading-bar">
                    <div class="percentage" :style="{ 'width' : percentage + '%'}"></div>
                </div>
                <div class="row download-text">
                    <span>Téléchargement de votre fichier en cours, veuillez patienter.</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import emitter from "../../events/emitter"

export default {
    props: {
        percentage: {
            type: Number,
            default: 1
        }
    },
    methods:{
        closePopup() {
            //this.$emit('showUploadDocumentPopup', false);
            emitter.emit("close-upload-popup")
        }
    }
}
</script>


<style scoped>
.popup{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
}

.popup-inner {
		background: #FFF;
		padding: 32px;
        width: 100%;
}

@media screen and (min-width: 960px){
.popup{
    position: fixed;
    width: auto;
    display: flex;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    }

.popup-inner {
		background: #FFF;
		padding: 32px;
        width: 800px;

}

}

.border-popup-custom{
      border-color: #E0E8FD !important;
      border : solid;
      border-width: 3px;
}


.download-text{
    color : #6A93FC;
}

.loading-bar{
    position: relative;
    margin : 0 auto;
    margin-top: 30px;
    margin-bottom: 50px;
    width: 80%;
    height: 15px;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid #ddd;
    border-color: #396FFB;
}

.percentage {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    display: block;
    height: 100%;
    border-radius: 15px;
    background-color: #396FFB;
    background-size: 30px 30px;
    background-image: linear-gradient(135deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    animation: animate-stripes 3s linear infinite;
}

.popup-title-up{
    display: inline-block;
}

.upload-popup-close-pos{
    float : right;
    color: #111179;
}

</style>
