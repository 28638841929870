<template>
    <div class="row">
        <span class="content-blocks-title py-3 text-start"><strong>{{title}}</strong></span>
        <div v-for="document in documents"
            v-bind:key='document.id'>
            <BlckSectionReadItem v-if="document.file" :document="document" />
            <BlckSectionItem v-else :document="document" />
        </div>
    </div>
</template>

<script>
import BlckSectionItem from '../../components/Consti-Dossier/BlckSectionItem.vue'
import BlckSectionReadItem from '../../components/Consti-Dossier/BlckSectionReadItem.vue'

export default {
    components: {
        BlckSectionItem,
        BlckSectionReadItem
    },
    props: {
        title: String,
        allowToUpload: {
            type: Boolean,
            default: false
        },
        documents: Array
    }
}
</script>
