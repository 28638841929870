<template>
    <object v-if='pdfContent && isPdfSupported()'
        :data=pdfContent
        :type=documentType
        width="100%" height="100%">
    </object>
    <object v-else-if='pdfContent'>
        <a @click="download">{{Block_Download}}</a>
    </object>
    <object v-else>
        <DataLoading :dataLoadingMask="dataLoadingMask">
            {{Block_No_Content}}
        </DataLoading>
    </object>
    <!--<DataLoading :dataLoadingMask="dataLoadingMask" style="width:100%; height:100%;">
        <PdfVuer v-if='pdfContent' :pdfUrl="pdfContent" style="width:100%; height:100%;"></PdfVuer>
    </DataLoading>-->
</template>

<script>
import DataLoading from '../Common/DataLoading';
//import PdfVuer from '../Common/PdfVuer';
import { base64toURL, downloadFileUrl } from '../../utils/file';

export default {
    components: {
        DataLoading,
        //PdfVuer
    },
    props: {
        dataLoadingMask: {
            type: Number,
            default: 0
        },
        documentType: {
            type: String,
            default: 'application/pdf'
        },
        downloadFileName: {
            type: String,
            default: null
        },
        pdfContent: null, // the content of the pdf to display
    },
    data() {
        return {
            Block_Download: "Télécharger le document",
            Block_No_Content: "Le contenu du fichier ne peut pas être affiché."
        }
    },
    methods: {
        /**
         * @param {String} data
         * @returns {String}
         */
        convert(data) {
            if (data.indexOf('data:application/pdf;base64,') === 0) {
                return base64toURL(data.replace('data:application/pdf;base64,', 'application/pdf'));
            }
            return data;
        },
        download() {
            let url = this.convert(this.pdfContent);
            let name = this.downloadFileName || 'document';
            downloadFileUrl(url, name);
        },
        /**
         * @returns {boolean}
         */
        isPdfSupported() {
            return navigator.pdfViewerEnabled
                || 'PDF Viewer' in navigator.plugins;
        }
    },
}
</script>

<style scoped lang="scss">
a {
    cursor: pointer;
    text-decoration: underline!important;
}
</style>
