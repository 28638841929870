class TypedAddress {
    /**
     * 
     * @param Object obj 
     */
    constructor(obj) {
        this.type = obj.type; // String!
        this.label = obj.label; // String
        this.postalCode = obj.postalCode; // String
        this.town = obj.town; // String
        this.country = obj.country; // String
    }

    /**
     * 
     * @returns {String} 
     */
    toString() {
        return ((this.label ?? "") + " "
            + (this.country ?? "") + " "
            + (this.postalCode ?? "") + " "
            + (this.town ?? "")).trim();
    }
}

export default TypedAddress;
