<template>
    <div class="lampe-container shadow">
        <img class="ct-dossier-lampe" src="@/assets/images/lampe.png" @click="handleAstuceModalDisplay()">
    </div>
    <div class="overlay_modal_lampe" v-bind:class="{ hide_element : isAstuceModalHidden }" @click="handleAstuceModalDisplay()"></div>
    <div class="modal-lampe-content rounded shadow" v-bind:class="{ hide_element : isAstuceModalHidden }" @click="handleAstuceModalDisplay()">
        <div class="row">
            <h4 class="text-center lampe-popup-title"
                id="lampePopupTextLabel"><strong>{{Block_Title}}</strong></h4>
        </div>
        <div class="row">
            <div class="col-10">
                <div class="text-start px-4">
                    <p class="lampe-popup-content">{{Popup_Text}}</p>
                    <p class="content-blocks-title mb-0"><strong>• Mail : <a :href='mailTo'>{{Popup_Email}}</a></strong></p>
                    <p class="content-blocks-title" v-if="Popup_Address"><strong>• Courrier : {{Popup_Address}}</strong></p>
                </div>
            </div>
            <div class="col-2">
                <p class="lampe-popup-title py-5"><i
                        class="bi bi-chevron-right" @click="handleAstuceModalDisplay()"></i></p>
            </div>
        </div>
    </div>

</template>
<script>
import TypedAddress from "../../models/typedAddress";
import getAdvisorRequest from '../../services/graphql/requests/getAdvisorRequest';

export default {
    data() {
        return {
            Block_Title : "ASTUCE",
            Popup_Text : "Vous pouvez nous transmettre vos documents également par:",
            Popup_Email : process.env.VUE_APP_CAN_EMAIL_ADDRESS,
            Popup_Address : "",
            isAstuceModalHidden : true
        };
    },
    computed: {
        mailTo() {
            return `mailto:${this.Popup_Email}`;
        }
    },
    methods:{
        timeout(ms) { //pass a time in milliseconds to this function
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        sleep(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        },
        handleAstuceModalDisplay(){
              this.isAstuceModalHidden = !this.isAstuceModalHidden;
        },
        /**
         * Request data
         */
        getData() {
            getAdvisorRequest().then((data) => {
                const advisor = data.client.rc;
                if (advisor) {
                    if (advisor.contact && advisor.contact.email) {
                        this.Popup_Email = advisor.contact.email;
                    }
                    if (advisor.local) {
                        const local = advisor.local;
                        if (local.address && local.address.length) {
                            this.Popup_Address = (new TypedAddress(local.address[0])).toString();
                        }
                    }
                }
            }).catch((reason) => {
                console.error(reason);
            });
        }
    },
    mounted() {
        this.getData();
    }
}
</script>

<style scoped>

.ct-dossier-lampe{
  background-color: white;
  margin-top : 5px;
  margin-left: -4px;
  height: 35px;
  width: 30px;
  cursor: pointer;
}

.lampe-popup-title{
  margin-top:10px;
  color : #396FFB;
}

.lampe-popup-content{
  color:#4C4C9A;
  margin-bottom: 10px;
}

.modal-lampe-content {
  position: absolute;
  background-color: #fff;
  top: 150px;
  right: 26px;
  bottom: 0;
  height: 200px;
  width: 430px;
  z-index: 99;
}

.content-blocks-title{ letter-spacing: 0; }

.overlay_modal_lampe { position: fixed; top: 0; left: 0; height: 100%; width: 100%; z-index: 10; }


@media screen and (max-width: 500px ) {
  .modal-lampe-content { display: none !important; }
}

</style>
