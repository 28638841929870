<template>
    <div
        :class="classObject">
        <!--<h5 class="upload-file-title"><img src="@/assets/images/epingle.svg">&nbsp;
            <b>{{Block_Bank_Add_File_Title}}</b></h5>-->
        <p class="upload-file-content px-5 my-0">
          <i>{{Block_Bank_Add_File_Description}}</i>
        </p>
    </div>
</template>

<script>
export default {
    props : {
        showBorder: Boolean
    },
    data() {
        return {
            Block_Bank_Add_File_Title : "Ajouter un fichier",
            Block_Bank_Add_File_Description : "Les fichiers doivent être au format jpg, png, pdf et ne doivent pas dépasser 10 Mo.",
            classObject: {
                "border": this.showBorder,
                "border-color-vio": this.showBorder,
                "block": this.showBorder,
                "shadow": this.showBorder,
                "p-3": this.showBorder,
                "mb-3": this.showBorder,
                "bg-white": this.showBorder,
                "rounded": this.showBorder,
                "text-center": this.showBorder,
            }
        };
    }
};
</script>


<style scoped>
.upload-file-title { font-size: 13px;
  color : #396FFB; }
.upload-file-title img { height: 16px; }

.upload-file-content{
  font-size: 12px;
  color : #707070;
}
</style>
