class DocumentInfo {
    /**
     * 
     * @param Object obj 
     */
    constructor(obj) {
        this.date = parseInt(obj.date);
        this.mimeType = obj.mimeType; // String!
        this.size = obj.size ? parseInt(obj.size) : null;
    }
}

export default DocumentInfo;
