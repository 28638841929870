<template>
  <div v-if="isVisible" class="DocumentReader">
    <div class="popup-backdrop"></div>
    <div class="popup-wrapper border shadow">
      <div class="row">
        <div>
          <span class="signature-popup-close-pos cursor-pointer"
            @click="closePopup()"><strong>X<i class="bi bi-x-lg"></i></strong></span>
        </div>
      </div>
      <div class="row mt-4">
        <p :class='["popup-content"]'>
          <pdf-wrapper
            :dataLoadingMask='dataLoadingMask'
            :documentType='documentType'
            :downloadFileName='document.label'
            :pdfContent='pdfContent'></pdf-wrapper>
        </p>
      </div>
      <br/>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import emitter from '../../events/emitter.js';
import events from '../../constants/events';
import PdfWrapper from "../Common/PdfWrapper";
import {base64toURL} from '../../utils/file.js';

export default {
  components: {
    PdfWrapper
  },
  data() {
    return {
      dataLoadingMask: 0,
      isVisible: false,
      document: null,
      documentType: 'application/pdf',
      isOpeningInNewTab: false,
      pdfContent: null // the content of the pdf to display
    }
  },
  mounted() {
    emitter.on(events.document.reader.show, ({document}) => {
      this.isVisible = true;
      this.document = document;
      this.displayDocument();
    });
    emitter.on(events.document.reader.hide, () => {
      this.isVisible = false;
      this.reset();
    });
  },
  unmounted() {
    emitter.off(events.document.reader.show);
    emitter.off(events.document.reader.hide);
  },
  methods: {
    ...mapActions('document', ['getDocumentContent']),
    closePopup() {
      emitter.emit(events.document.reader.hide);
    },
    displayDocument() {
      this.getDocumentContent(this.document.id).then((data) => {
        if (data) {
          this.documentType = this.document.file.mimeType || '';
          const url = base64toURL(data, this.documentType);
          //const url = 'data:' + this.documentType + ';base64,' + data;
          if (this.isOpeningInNewTab) {
            window.open(url, '_blank');
            this.closePopup();
          } else {
            this.pdfContent = url;
          }
        }
      }).finally(() => {
        this.dataLoadingMask = 0;
      });
      this.dataLoadingMask = 1;
    },
    reset() {
      this.document = null;
      this.pdfContent = null;
    }
  }
}
</script>

<style scoped lang="scss">
.popup-wrapper {
  top: 1vh;
  height: 98vh;
  width: 98vw;
  overflow: hidden;
}

.popup-content {
  height: 90vh;
  width: 98%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
