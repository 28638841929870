<template>
    <div class="col-12 col-lg-12">
        <div
            class="border border-color-vio block shadow p-3 mb-3 mt-5 bg-white rounded text-center">
            <img src="@/assets/images/dot-interro.svg" class="cmdossier-dot-icon">
            <h4 class="constituer-sub-content-title pt-3">{{Block_Info_Title}}</h4>
            <p class="block-content-font">
                <b class="constituer-dossier-bold-content">{{Block_Info_Description_b}}</b> {{Block_Info_Description_Following}}
            </p>
            <BlckAddFile/>
        </div>
    </div>
</template>

<script>
import BlckAddFile from "./BlckAddFile";

export default {
    components: {
        BlckAddFile
    },
    data() {
        return {
            Block_Info_Title : "COMMENT ?",
            Block_Info_Description_b : "Cliquez sur le nom du document",
            Block_Info_Description_Following : "pour importer un fichier depuis votre ordinateur.",
        };
    }
};
</script>

<style scoped>
  .cmdossier-dot-icon { height: 31px; }
</style>
