import { computed } from "vue";
import { useStore } from "vuex";

export default function setup() {
    const store = useStore();

    const computedDocuments = computed(() => store.getters[`document/getDocuments`]);
    const computedLegalDocuments = computed(() => store.getters[`document/getLegalDocuments`]);
    const computedSigningDocuments = computed(() => store.getters[`document/getDocumentsSigning`])

    return {
        computedDocuments,
        computedLegalDocuments,
        computedSigningDocuments,
        store
    }
}
