import DocumentCategory from "./documentCategory";
import DocumentInfo from "./documentInfo";

class Document {
    /**
     * 
     * @param Object obj 
     */
    constructor(obj) {
        this.id = parseInt(obj.id);
        this.date = parseInt(obj.date);
        this.dateOfAsk = parseInt(obj.dateOfAsk);
        this.label = obj.label; // String!
        this.category = obj.category ? new DocumentCategory(obj.category) : null;
        this.type = obj.type; // String
        this.completed = obj.completed; // Boolean!
        this.signingAvailable = obj.signingAvailable; // Boolean!
        this.file = obj.file ? new DocumentInfo(obj.file) : null;
        this.links = obj.links ?? []; // array
    }
}

export default Document;
