<template>
  <div class="position-relative">
    <div
        class="border border-color-vio block rounded shadow p-3 mb-3 bg-white rounded block-documents-attr">
      <h4 class=" text-center content-blocks-title "><b>{{ Block_Title }}</b></h4>
      <hr/>
      <div class="">
        <div class="">
          <p class="block-content-font text-start fw-light">{{ Block_Description }}</p>
          <BlckInfo/>
        </div>

        <DataLoading :dataLoadingMask="dataLoadingMask" :noSlot="true"/>
        <div v-for="section in sections"
             v-bind:todo="section"
             v-bind:key="section.title"
             class="">
          <BlckSection :title="section.title"
                       :documents="section.documents"/>
        </div>
      </div>
    </div>
    <BlckAstucePopup/>
  </div>
</template>

<script>
import emitter from "../../events/emitter";
import DataLoading from "../../components/Common/DataLoading";
import BlckAstucePopup from "../../components/Consti-Dossier/BlckAstucePopup.vue"
import BlckInfo from "../../components/Consti-Dossier/BlckInfo.vue"
import BlckSection from "../../components/Consti-Dossier/BlckSection.vue"
import document_type from "../../constants/document_type";
import DocumentsMixin from '../../mixins/documents';
import DocumentsSetup from '../../mixins/documentsSetup';
import Document from "../../models/document";
import {TRACK_FOLDER_PATH} from "../../router/pathes";

export default {
  setup() {
    return DocumentsSetup();
  },
  components: {
    BlckAstucePopup,
    BlckInfo,
    BlckSection,
    DataLoading
  },
  data() {
    return {
      Block_Title: "TRANSMETTRE MES DOCUMENTS",
      Block_Description: "Vous devez nous transmettre les pièces justificatives listées ci-dessous pour la constitution de votre dossier.",
      Block_Doc_Juridique_To_Validate: "Vous devez lire et valider les documents juridiques pour pouvoir téléverser vos documents.",
      Block_Access_Button: "Accéder",
      dataLoadingMask: 0,
      sections: []
    };
  },
  mixins: [DocumentsMixin],
  methods: {
    getData() {
      this.dataLoadingMask = 2 + 4;
      this.getLegalDocumentListByMainDossier().then()
          .catch((reason) => {
            console.error(reason);
          }).finally(() => {
        this.dataLoadingMask -= 2;
      });
      this.getDocumentListByMainDossier().then((documents) => {
        this.sections = this.mapSections(documents);
      }).finally(() => {
        this.dataLoadingMask -= 4;
      });
    },
    /**
     * @param array documents
     */
    mapSections(documents) {
      let sections = {};

      documents.forEach((document) => {
        if (document.type != document_type.OTHER) {
          const categoryId = document.category.id;
          if (!sections[categoryId]) {
            sections[categoryId] = {
              id: categoryId,
              title: `${document.category.label}`,
              documents: [new Document(document)]
            };
          } else {
            sections[categoryId].documents.push(new Document(document));
          }
        }
      });

      return sections;
    }
  },
  mounted() {
    emitter.on('close-upload-popup', ({refresh}) => {
      if (refresh) {
        this.getData();
      }
    });
  },
  created() {
    this.TRACK_FOLDER_PATH = TRACK_FOLDER_PATH
  }
}
</script>
