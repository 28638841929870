<template>
  <div class="border border-color-vio block rounded shadow p-3 mb-5 bg-white rounded algoan-blck">
    <h4><b>{{ Title }}</b></h4>
    <p>{{ Description }}</p>
    <button :disabled="!AlgoanLoaded" type="button" class="algoan-access"  @click="navigateTo()">
      <img class="algoan-secu-icon" src="@/assets/images/Icone_secu_algoan.svg"/>
        {{ Access }}
      <div class="arrow-blue"/>
    </button>
  </div>
</template>

<script>
import getAdvisorRequest from '../../services/graphql/requests/getAdvisorRequest';
import {getInstance} from "@/auth";
import {mapActions} from "vuex";

export default {
  name: "BlckAlgoan",
  mounted() {
    this.getData();
  },
  data() {
    return {
      Title: "Moins de papier, plus de sécurité !",
      Description: "Donnez accès à vos relevés de compte bancaire en toute sécurité.",
      Access: "Je connecte mes relevés",
      clientLastname: '',
      rc: '',
      AlgoanLoaded: false,
    };
  },
  methods: {
    ...mapActions('client', ['getMainClient']),
    navigateTo() {
      let dossierId = getInstance().getUserDossierId();
      dossierId = 'DSTO' + ('00000000' + dossierId).slice(-8);

      const url = `https://agregation.starto.fr/?dossier=${dossierId}&utm_source=ec`;
      window.open(url);
    },
    getData() {
      getAdvisorRequest().then(async (data) => {
        this.rc = data.client.rc;
        this.AlgoanLoaded = true;
      }).catch((reason) => {
        console.error(reason);
      });

      this.getMainClient().then((client) => {
        this.clientLastname = client.lastname;
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/colors.scss';
  .algoan-secu-icon {
    margin-right: 8px;
  }

  .algoan-access {
    background-color: white;
    color: $color-primary;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    margin: 4px 2px;
    border-radius: 30px;
    border-width: 0;
    min-width: 220px;
  }

  .algoan-access:disabled {
    background-color: #a1a0a0;
  }

  .algoan-blck {
    background: url('~@/assets/images/Background_algoan.svg') no-repeat;
    background-size: cover;
    color: white;
  }
</style>